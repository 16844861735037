<route>
{
  "meta": {
    "permission": [
      "product.view_productiondetails"
    ]
  }
}
</route>

<template>
  <v-row class="my-0">
    <v-col class="pt-0" cols="12">
      <!-- Modal -->
      <dialdetail
        container="modal"
        ref="dialdetail"
        :mode="mode"
        :process="process"
        :product="product"
        :title="this.$tc(this.title, 1)"
        :type="title"
        @done="reload = true"
        v-model="detailsContainerfield"
      ></dialdetail>

      <!-- Lista -->
      <i-card-list
        class="details-list"
        :dontRemove="sale"
        :dontCreate="mode === 'item' || sale"
        :title="`${this.$tc(this.title, 1)}`"
        :noTitle="mode === 'item'"
        :simple="mode === 'item'"
        :app="
          mode === 'item' && product === null
            ? 'product.process'
            : mode === 'subitem' && product === null
            ? 'product.processsubitems'
            : mode === 'item' && product !== null
            ? 'product.productiondetails'
            : 'product.detailssubitems'
        "
        :api="
          mode === 'item' && product === null
            ? 'product.processitem'
            : mode === 'subitem' && product === null
            ? 'product.processsubitem'
            : mode === 'item' && product !== null
            ? 'product.details'
            : 'product.subdetails'
        "
        :method="'list'"
        :opt="{
          product: product !== null ? product.pk : null,
          template: process.template,
          detail: process.pk
        }"
        :field="mode === 'item' ? 'detail' : 'subdetail'"
        :selected="selected"
        :reload.sync="reload"
        @wasRemove="wasRemove = $event"
        @click:create="sale ? '' : open()"
        @click:edit="sale ? '' : open($event)"
      >
        <template v-slot:[`item`]="{ item }">
          <!--
            Switch que se visualiza en la orden de venta para seleccionar
            el detalle o subdetalle que se desea asociar a la orden de venta
            @mode: para discriminar el comportamiento del detalle de
            producción, puede tomar los valores item o subitem
          -->
          <span
            v-if="!(process.subitems && mode == 'item') && sale"
            align="end"
          >
            <!--
              @orderDetails.add: permiso de usuario para agregar detalles
              @closed: si el item o subiten no es multichoice, se bloquea
              la selección y cambia a color rojo, así sólo seleccionar uno
              @selected: items o subitems seleccionados para ser guardados
              en la venta
            -->
            <v-switch
              v-if="
                orderDetails.add &&
                !closed &&
                selected !== null &&
                !(
                  selected.filter(
                    (event) =>
                      event[mode === 'item' ? 'detail' : 'subdetail'] == item.pk
                  ).length > 0
                )
              "
              color="primary"
              key="save-input"
              name="save"
              :label="$tc('save')"
              @click="submitSale(item)"
              v-model="item.select"
            ></v-switch>
          </span>

          <!-- Título -->
          <h3 class="title primary--text font-weight-black mb-1">
            {{ item.name }}
          </h3>

          <!-- Detalles del item -->
          <div class="details-list--item primary--text">
            <!-- Tipo de precio -->
            <div class="font-weight-black">
              {{ priceTypeName(item) }}
            </div>

            <!-- Nombre de la fórmula -->
            <div v-if="item.calc !== null">
              {{ `${item.calc}` }}
            </div>

            <!-- Costo -->
            <div v-if="item.cost > 0">
              <span class="font-weight-black">{{ `${$tc('cost', 1)}: ` }}</span>
              <i-money class="primary--text" mode="text" :value="item.cost" />
            </div>

            <!-- Precio por tipo cliente -->
            <v-list-group
              v-if="item.cost > 0"
              class="mb-3"
              @click.stop="getPrices(item)"
            >
              <v-icon slot="appendIcon" size="12">fas fa-arrow-down</v-icon>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="primary--text caption font-weight-black">
                      {{ $tc('price', 2) }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <!-- Lista de precios por tipo de cliente -->
              <v-list-item
                v-show="
                  prices != undefined &&
                  ((mode == 'subitem' && prices[0].subdetail === item.pk) ||
                    mode == 'item')
                "
                dense
                v-for="param in prices"
                :key="'param-' + param.pk"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="pl-3 primary--text"
                    style="font-size: 13px; font-weight: 400"
                  >
                    <b>&middot;</b>
                    <i-money mode="text" :value="param.price">
                      <span class="font-weight-black">
                        {{ `${param.name}:` }}
                      </span>
                    </i-money>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </template>
      </i-card-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import dialdetail from '../../pages/details/_pk.vue'
export default {
  components: {
    dialdetail
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    process: {
      type: Object,
      default: null
    },
    mode: {
      type: String
    },
    title: {
      type: String,
      default: 'details_production'
    },
    sale: {
      type: Boolean,
      default: false
    },
    subOrder: {
      type: [Number, String],
      default: ''
    },
    level: {
      type: [Number, String],
      default: ''
    }
  },
  data: () => ({
    detailsContainerfield: false,
    prices: {},
    loadPrice: false,
    reload: false,
    detail: null,
    closed: false,
    selected: null,
    field:
      'selected !== null && !(selected.filter((event) => event.subdetail == item.pk).length > 0)',
    wasRemove: false,
    saleDetail: {
      price: 0,
      priceString: '',
      dimensions: {},
      multi_choice: false,
      detail: '',
      formula: '',
      subdetail: '',
      subOrder: '',
      select: false
    }
  }),
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    /**
     * orderDetails
     * Método para obterner los permisos de usuarios,
     * los permisos de delete, view y edit, se toman en i-card-list
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    orderDetails() {
      return {
        add: this.getPermissions([`sales.add_orderdetails`]) || this.isAdmin
      }
    }
  },
  watch: {
    wasRemove: {
      handler(d) {
        if (d === true && this.mode === 'item') {
          this.$emit('wasRemove', true)
          this.wasRemove = false
        }
      }
    }
  },
  methods: {
    /**
     * getPrices
     * Método para filtrar los tipos de precios para cada item o subitem
     * pertenecientes a una platilla, antes o después de relacionarla
     * a un producto
     * @prices: precios por tipo de cliente para cada item o subitem
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getPrices(item) {
      // Precios por tipo de cliente de cada item o subitem de la
      // plantilla de proceso de producción
      if (this.product === null) {
        this.loadPrice = true
        try {
          const response = await this.$api.product.processprices.list({
            opt: {
              params: {
                mode: 'process',
                template: item.template,
                detail: this.mode === 'subitem' ? item.item : item.pk,
                subdetail: this.mode === 'subitem' ? item.pk : null
              }
            }
          })
          this.prices = response.data
        } finally {
          this.loadPrice = false
        }
      } else {
        // Precios por tipo de cliente de cada item o subitem de la
        // plantilla de proceso de producción asociada a un producto
        this.loadPrice = true
        try {
          const response = await this.$api.product.prices.list({
            opt: {
              params: {
                mode: 'detail',
                product: this.product.pk,
                detail: this.mode === 'subitem' ? item.item : item.pk,
                subdetail: this.mode === 'subitem' ? item.pk : null,
                level: this.sale ? this.level : null
              }
            }
          })
          this.prices = response.data
        } finally {
          this.loadPrice = false
        }
      }
    },
    /**
     * open
     * Método para abrir modo diálogo el formulario de un detalle
     * de producción (tipo item o subitem)
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    open(item) {
      this.detailsContainerfield = true
      this.$refs.dialdetail.getDetails(item !== undefined ? item.pk : undefined)
    },
    /**
     * submitSale
     * Método para guardar el (item o subitem) en la orden de venta
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submitSale(item) {
      this.detail = item
      this.saleDetail.price = 0
      this.saleDetail.dimensions = {}
      this.saleDetail.multi_choice = item.multi_choice
      this.saleDetail.detail = this.mode === 'item' ? item.pk : item.item
      this.saleDetail.formula = item.formula
      this.saleDetail.subdetail = this.mode === 'item' ? null : item.pk
      this.saleDetail.subOrder = this.subOrder
      await this.$api.suborder.production.create({
        form: this.saleDetail,
        opt: {
          params: {
            detail: item.pk,
            level: this.level
          }
        }
      })
      this.$toast.success(
        `${this.$tc(this.title, 1)} ${this.$tc(
          this.saleDetail.pk !== undefined ? 'edited' : 'created',
          2
        )}`
      )
      this.getMultichoice()
      this.$emit('reload', true)
    },
    /**
     * getMultichoice
     * Método para consultar los items o subitems guardados en la orden de venta
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMultichoice() {
      let response = await this.$api.suborder.production.show({
        pk: this.subOrder,
        opt: {
          params: {
            subOrder: true,
            detail: this.process.pk
          }
        }
      })
      this.selected = response.data
      if (this.selected !== null) {
        // @closed: Variable para desactivar el switch de guardado y cambiar
        // de color el seleccionado en caso de que no sea multichoice
        this.closed = this.selected.length > 0 && !this.selected[0].multi_choice
      }
    },
    /**
     * priceTypeName
     * Método para determinar la traducción del tipo de precio de un item
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    priceTypeName(item) {
      const NAMES = {
        base: () => `${this.$t('base')}`,
        formula: () => `${this.$t('formula_simple')}`,
        matrix: () => `${this.$t('matrix')}`,
        bool: () => `${this.$t('without_price')}`
      }
      return NAMES[item.price_type] ? NAMES[item.price_type]() : ''
    }
  },
  mounted() {
    if (this.sale) {
      this.getMultichoice()
    }
  }
}
</script>
<style lang="sass">
.details-list
  > .theme--light.v-card
    background-color: transparent
  br
    display: none
  .col-12
    padding-top: 0
  .display-1.font-weight-black.grey--text.col.col-12,
  .col-sm-4.col-md-4.col-12
    padding: 12px 5px
.details-list--item
  display: flex
  flex-direction: column
  gap: 5px
  padding: 15px 10px
  padding-top: 0
</style>
