<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="newItemForm">
    <form @submit.prevent="handleSubmit(submit)">
      <v-row class="my-0">
        <v-col cols="12" md="8" lg="9">
          <v-row class="my-0">
            <!-- Producto -->
            <v-col v-if="product" class="pt-3" cols="12" md="6" lg="4">
              <v-text-field
                autocomplete="off"
                disabled
                hide-details
                outlined
                :label="$tc('product', 1)"
                v-model="product.name"
              ></v-text-field>
            </v-col>

            <!-- Plantilla -->
            <v-col class="pt-3" cols="12" md="6" lg="4">
              <v-text-field
                autocomplete="off"
                disabled
                hide-details
                outlined
                :label="$t('template')"
                v-model="templateName"
              ></v-text-field>
            </v-col>

            <!-- Nombre -->
            <v-col class="pt-3" cols="12" md="6" lg="4">
              <ValidationProvider
                vid="name"
                :name="$tc('name', 1)"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  outlined
                  :error-messages="errors[0]"
                  :label="$tc('name', 1)"
                  v-model="newItem.name"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <!-- Tipo de precio -->
            <v-col class="pt-3" cols="12" md="6" lg="4">
              <v-select
                outlined
                item-text="state"
                item-value="abbr"
                :items="[
                  { state: $t('base'), abbr: 'base' },
                  { state: $t('formula'), abbr: 'formula' },
                  { state: $t('matrix'), abbr: 'matrix' },
                  { state: $t('without_price'), abbr: 'bool' }
                ]"
                :label="$tc('price', 3)"
                v-model="newItem.price_type"
              ></v-select>
            </v-col>

            <!-- Lista de fórmulas -->
            <v-col
              v-show="disabled.is_formula"
              class="pt-3"
              cols="12"
              md="6"
              lg="4"
            >
              <ValidationProvider
                vid="formula"
                :name="$tc('formula')"
                :rules="disabled.is_formula ? 'required' : ''"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  class="secondary--text"
                  append-icon="fa-search"
                  autocomplete="off"
                  clearable
                  key="formula-input"
                  outlined
                  :error-messages="errors[0]"
                  :items="formulas"
                  item-text="name"
                  item-value="pk"
                  :label="$tc('formula')"
                  v-model="newItem.formula"
                >
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>

        <!-- Atributos posibles -->
        <v-col cols="12" md="4" lg="3">
          <h2 class="title">{{ $t('attributes') }}</h2>

          <!-- Con subitems -->
          <v-switch
            class="v-switch mt-0"
            color="primary"
            key="subitems-input"
            name="subitems"
            :disabled="disabled.subitems"
            :label="$tc('with_subitems')"
            v-model="newItem.subitems"
          ></v-switch>

          <!-- Selección múltiple -->
          <v-switch
            class="v-switch mt-0"
            color="primary"
            key="multi_choice-input"
            name="multi_choice"
            :disabled="disabled.multi_choice"
            :label="$tc('multi_choice')"
            v-model="newItem.multi_choice"
          ></v-switch>

          <!-- Costo por dimensiones -->
          <v-switch
            v-show="disabled.is_formula"
            class="v-switch mt-0"
            color="primary"
            key="have_dimensions-input"
            name="have_dimensions"
            :label="$tc('cost_by_dimensions')"
            v-model="newItem.have_dimensions"
          ></v-switch>

          <!-- Permitir multiplicar por la cantidad de producto -->
          <v-switch
            v-show="newItem.price_type !== 'matrix'"
            class="v-switch mt-0"
            color="primary"
            key="is_multipliable-input"
            name="is_multipliable"
            :disabled="newItem.price_type === 'bool'"
            :label="$tc('is_multipliable')"
            v-model="newItem.is_multipliable"
          ></v-switch>
        </v-col>

        <!-- Botón de guardar -->
        <v-col class="align-self-center text-right" cols="12">
          <i-btn color="primary" :title="$tc('save', 1)" @click="validateForm">
            <v-icon>mdi-content-save</v-icon>
          </i-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    typeProcess: {
      type: String,
      default: 'preproduction'
    },
    product: {
      type: Object,
      default: null
    },
    template: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      load: false,
      templateName: '',
      disabled: {
        subitems: false,
        multi_choice: false,
        is_formula: false
      },
      newItem: {
        formula: '',
        have_dimensions: false,
        multi_choice: false,
        is_multipliable: true,
        name: '',
        price_type: 'base',
        subitems: true,
        template: 0,
        typeProcess: this.typeProcess
      },
      formulas: []
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    /**
     * validateForm
     * Método para validar que el formulario se llenó correctamente.
     * Se agregó como un método adicional para poder usar el submit como
     * async
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateForm() {
      this.$refs.newItemForm.validate().then((success) => {
        if (success) {
          this.submit()
        }
      })
    },
    /**
     * submit
     * Método para guardar un proceso de producción (item) de un template o
     * producto
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async submit() {
      try {
        let result = ''
        if (this.product) {
          result = await this.$api.product.details.create({
            form: this.newItem
          })
        } else {
          result = await this.$api.product.processitem.create({
            form: this.newItem
          })
        }
        this.createPrices(result.data)
        this.$parent.$parent.$parent.$parent.$emit('done')
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * templateName
     * Método para consultar el nombre del template del item que se está
     * creando. Funciona solo para items de template porque el producto ya
     * trae el nombre.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async queryTemplateName(templatePK) {
      let query = await this.$api.product.template.show({
        pk: templatePK
      })
      this.templateName = query.data.name
    },
    /**
     * getFormulas
     * Método consultar la lista de fórmulas
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getFormulas() {
      const formula = await this.$api.formula.list({})
      this.formulas = formula.data.results
    },
    /**
     * createPrices
     * Método para crear precios por tipo de cliente de los ítems
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async createPrices(item) {
      try {
        await this.$api.product.pricescreate({
          opt: {
            params: {
              product: item.product ? item.product : null,
              mode: 'detail',
              template: item.template,
              detail: item.pk,
              company_id: this.company.pk
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    /**
     * newItem.subitems
     * Watcher que desactiva el switch de selección múltiple si
     * subitems está desactivado
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    'newItem.subitems': {
      handler(newValue) {
        this.disabled.multi_choice = !newValue
        if (!newValue) {
          this.newItem.multi_choice = newValue
        }
      }
    },
    /**
     * newItem.price_type
     * Watcher que desactiva el switch de dimensiones si el tipo de precio
     * no es 'formula'
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    'newItem.price_type': {
      handler(type) {
        this.disabled.is_formula = type === 'formula' ? true : false
        this.newItem.is_multipliable = type === 'bool' ? false : true
        this.newItem.is_multipliable = type === 'matrix' ? false : true
      }
    }
  },
  mounted() {
    this.getFormulas()
    if (this.template) {
      this.queryTemplateName(this.template)
      this.newItem.template = this.template
    }

    if (this.product) {
      this.templateName = this.product.template_name
      this.newItem.product = this.product.pk
      this.newItem.template = this.product.template
    }
  }
}
</script>
