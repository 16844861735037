var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('dialdetail',{ref:"dialdetail",attrs:{"container":"modal","mode":_vm.mode,"process":_vm.process,"product":_vm.product,"title":this.$tc(this.title, 1),"type":_vm.title},on:{"done":function($event){_vm.reload = true}},model:{value:(_vm.detailsContainerfield),callback:function ($$v) {_vm.detailsContainerfield=$$v},expression:"detailsContainerfield"}}),_c('i-card-list',{staticClass:"details-list",attrs:{"dontRemove":_vm.sale,"dontCreate":_vm.mode === 'item' || _vm.sale,"title":`${this.$tc(this.title, 1)}`,"noTitle":_vm.mode === 'item',"simple":_vm.mode === 'item',"app":_vm.mode === 'item' && _vm.product === null
          ? 'product.process'
          : _vm.mode === 'subitem' && _vm.product === null
          ? 'product.processsubitems'
          : _vm.mode === 'item' && _vm.product !== null
          ? 'product.productiondetails'
          : 'product.detailssubitems',"api":_vm.mode === 'item' && _vm.product === null
          ? 'product.processitem'
          : _vm.mode === 'subitem' && _vm.product === null
          ? 'product.processsubitem'
          : _vm.mode === 'item' && _vm.product !== null
          ? 'product.details'
          : 'product.subdetails',"method":'list',"opt":{
        product: _vm.product !== null ? _vm.product.pk : null,
        template: _vm.process.template,
        detail: _vm.process.pk
      },"field":_vm.mode === 'item' ? 'detail' : 'subdetail',"selected":_vm.selected,"reload":_vm.reload},on:{"update:reload":function($event){_vm.reload=$event},"wasRemove":function($event){_vm.wasRemove = $event},"click:create":function($event){_vm.sale ? '' : _vm.open()},"click:edit":function($event){_vm.sale ? '' : _vm.open($event)}},scopedSlots:_vm._u([{key:`item`,fn:function({ item }){return [(!(_vm.process.subitems && _vm.mode == 'item') && _vm.sale)?_c('span',{attrs:{"align":"end"}},[(
              _vm.orderDetails.add &&
              !_vm.closed &&
              _vm.selected !== null &&
              !(
                _vm.selected.filter(
                  (event) =>
                    event[_vm.mode === 'item' ? 'detail' : 'subdetail'] == item.pk
                ).length > 0
              )
            )?_c('v-switch',{key:"save-input",attrs:{"color":"primary","name":"save","label":_vm.$tc('save')},on:{"click":function($event){return _vm.submitSale(item)}},model:{value:(item.select),callback:function ($$v) {_vm.$set(item, "select", $$v)},expression:"item.select"}}):_vm._e()],1):_vm._e(),_c('h3',{staticClass:"title primary--text font-weight-black mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"details-list--item primary--text"},[_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.priceTypeName(item))+" ")]),(item.calc !== null)?_c('div',[_vm._v(" "+_vm._s(`${item.calc}`)+" ")]):_vm._e(),(item.cost > 0)?_c('div',[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(`${_vm.$tc('cost', 1)}: `))]),_c('i-money',{staticClass:"primary--text",attrs:{"mode":"text","value":item.cost}})],1):_vm._e(),(item.cost > 0)?_c('v-list-group',{staticClass:"mb-3",on:{"click":function($event){$event.stopPropagation();return _vm.getPrices(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"primary--text caption font-weight-black"},[_vm._v(" "+_vm._s(_vm.$tc('price', 2))+" ")])])],1)]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"slot":"appendIcon","size":"12"},slot:"appendIcon"},[_vm._v("fas fa-arrow-down")]),_vm._l((_vm.prices),function(param){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.prices != undefined &&
                ((_vm.mode == 'subitem' && _vm.prices[0].subdetail === item.pk) ||
                  _vm.mode == 'item')
              ),expression:"\n                prices != undefined &&\n                ((mode == 'subitem' && prices[0].subdetail === item.pk) ||\n                  mode == 'item')\n              "}],key:'param-' + param.pk,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-3 primary--text",staticStyle:{"font-size":"13px","font-weight":"400"}},[_c('b',[_vm._v("·")]),_c('i-money',{attrs:{"mode":"text","value":param.price}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(`${param.name}:`)+" ")])])],1)],1)],1)})],2):_vm._e()],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }