<template>
  <div v-if="!wasRemove" class="container-process-form">
    <v-expansion-panels
      v-if="processBtn.viewItem"
      accordion
      focusable
      flat
      hover
      v-model="panel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="title">
            {{
              processForm.pk === '' ? $tc('item', 1) : $tc(processForm.name, 1)
            }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="my-0">
            <!-- Item nuevo -->
            <v-col
              v-if="processForm.pk === ''"
              class="pt-0"
              cols="12"
              key="new-item"
            >
              <newItem
                :typeProcess="type"
                :product="product"
                :template="processForm.template"
              ></newItem>
            </v-col>

            <!-- Item guardado -->
            <v-col v-else class="pt-0" cols="12" key="item-saved">
              <v-row class="mb-0">
                <!-- Detalles de un item guardado -->
                <v-col class="pt-0" cols="12" md="4" lg="3">
                  <detail
                    :product="product"
                    :process="processForm"
                    :mode="'item'"
                    :title="processForm.name"
                    :sale="sale"
                    :subOrder="subOrder"
                    :level="level"
                    @wasRemove="wasRemove = $event"
                  ></detail>
                </v-col>

                <!-- Sub-detalles -->
                <v-col
                  v-if="processForm.subitems"
                  class="pt-5"
                  cols="12"
                  md="8"
                  lg="9"
                >
                  <detail
                    :product="product"
                    :process="processForm"
                    :mode="'subitem'"
                    :title="
                      $t('subitems', {
                        value: $tc(processForm.name, 1)
                      })
                    "
                    :sale="sale"
                    :subOrder="subOrder"
                    :level="level"
                    @wasRemove="wasRemove = $event"
                  ></detail>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import detail from '../product/details.vue'
import newItem from './newItem.vue'
export default {
  components: {
    detail,
    newItem
  },
  props: {
    process: {
      required: true,
      type: [Object],
      default: null
    },
    type: {
      type: String
    },
    product: {
      type: Object,
      default: null
    },
    sale: {
      type: Boolean,
      default: false
    },
    subOrder: {
      type: [Number, String],
      default: ''
    },
    level: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      add: false,
      panel: false,
      load: false,
      processForm: this.process,
      boolean: false,
      wasRemove: false
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    async getForm() {
      if (this.processForm.pk == '') this.processForm.name = ''
    },
    /**
     * submit
     * Método para guardar un item con tipo de precio base por defecto
     * antes de configurarlo
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        this.processForm.price_type = 'base'
        this.processForm.typeProcess = this.type
        this.processForm.boolean = this.boolean
        // Creación y edición del item en la plantilla de proceso de producción
        if (this.product === null) {
          let item =
            this.processForm.pk === '' || this.processForm.pk === undefined
              ? await this.$api.product.processitem.create({
                  form: this.processForm
                })
              : await this.$api.product.processitem.edit({
                  pk: this.processForm.pk,
                  form: this.processForm
                })
          this.processForm = item.data
        } else {
          // Creación y edición del item en la plantilla de proceso de producción
          // asociado a un producto
          this.processForm.product = this.product.pk
          this.processForm.template = this.product.template
          let item =
            this.processForm.pk === '' || this.processForm.pk === undefined
              ? await this.$api.product.details.create({
                  form: this.processForm
                })
              : await this.$api.product.details.edit({
                  pk: this.processForm.pk,
                  form: this.processForm
                })
          this.processForm = item.data
        }
        this.$emit('done')
      } finally {
        this.load = false
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    /**
     * processBtn
     * Permisos de usuario
     * @addSubItems: agregar subitems
     * @viewItem: ver items
     * @editItem: editar items
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    processBtn() {
      return {
        addSubItems:
          this.getPermissions([`product.add_process`]) ||
          this.getPermissions([`product.add_productiondetails`]) ||
          this.isAdmin,
        viewItem:
          this.getPermissions([`product.view_process`]) ||
          this.getPermissions([`product.view_productiondetails`]) ||
          this.isAdmin,
        editItem:
          this.getPermissions([`product.change_process`]) ||
          this.getPermissions([`product.change_productiondetails`]) ||
          this.isAdmin
      }
    }
  },
  mounted() {
    this.getForm()
  }
}
</script>
<style lang="sass">
.container-process-form
  border-radius: 4px
  margin-bottom: 8px
  background-color: transparent
  .v-expansion-panel-content__wrap
    padding: 24px 16px
  .v-text-field--outlined fieldset
    background-color: var(--v-auxbg-base)

.theme--light
  .container-process-form
    .v-expansion-panel
      background-color: #f0f0f0
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
    .v-expansion-panel-content
      background-color: #f0f0f0
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
      .details-list > .v-card
        background-color: #f0f0f0

.theme--dark
  .container-process-form
    .v-expansion-panel
      background-color: #292929
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
    .v-expansion-panel-content
      background-color: #292929
      border: 1px solid #5C5C5C
      border-top: none
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
      .details-list > .v-card
        background-color: #292929
</style>
