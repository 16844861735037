<route>
{
  "meta": {
    "permission": [
      "product.view_process"
    ]
  }
}
</route>

<template>
  <i-container :opts="opts" :mode="container" @input="$emit('input', $event)">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            v-if="product === null"
            :title="`${$tc('template')}`"
            :loading="load"
            dontSave
          >
          </i-toolbar>
          <v-card-text class="template-v-card-text">
            <v-row class="pt-5 pb-2">
              <!-- Nombre de la plantilla -->
              <v-col v-if="product === null" class="pt-0" cols="10" md="11">
                <ValidationProvider
                  vid="template"
                  :name="$tc('name', 1)"
                  rules="required|max:64"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="secondary"
                    outlined
                    maxlength="64"
                    :error-messages="errors[0]"
                    :label="$tc('name', 1)"
                    v-model="template.name"
                  />
                </ValidationProvider>
              </v-col>
              <v-col v-else class="pt-0" cols="12">
                <v-text-field
                  color="secondary"
                  disabled
                  name="name"
                  outlined
                  :label="$tc('name', 1)"
                  v-model="product.template_name"
                />
              </v-col>

              <!-- Botón de guardar -->
              <v-col
                v-if="product === null"
                class="align-self-right text-center"
                cols="2"
                md="1"
              >
                <i-btn
                  color="primary"
                  icon="fa-save"
                  outlined
                  :title="$tc('save', 1)"
                  @click="submitTemplate"
                ></i-btn>
              </v-col>
            </v-row>

            <!-- Tabs -->
            <v-card class="template-card-content">
              <!-- Overlay -->
              <span v-if="template.pk > 0 || product !== null"></span>
              <div v-else class="template-overlay">
                {{ $t('save_template') }}
              </div>

              <!-- Nombres -->
              <v-tabs v-model="tab">
                <v-tab
                  v-for="(type, i) in typeProcess"
                  :key="`type-${i}`"
                  color="primary"
                >
                  {{ $t(type) }}
                </v-tab>
              </v-tabs>

              <!-- Contenido -->
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(type, i) in typeProcess" :key="`type-${i}`">
                  <v-card flat>
                    <v-card-text>
                      <!-- Botón - Crear nuevo / Descartar nuevo -->
                      <div
                        v-if="processBtn.addItem && !sale"
                        class="d-flex w-100 mb-2"
                      >
                        <template v-if="addList[type] == false">
                          <v-btn
                            color="secondary"
                            :title="`${$t('add', { value: $t(type) })}`"
                            @click="addList[type] = true"
                          >
                            <v-icon class="mr-1" small>fa-plus</v-icon>
                            {{ `${$t('create', { model: $tc('new') })}` }}
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            color="error"
                            :title="`${$t('add', { value: $t(type) })}`"
                            @click="addList[type] = false"
                          >
                            <v-icon class="mr-1" small>fa-times</v-icon>
                            {{ `${$tc('discard', 0)} ${$tc('new')}` }}
                          </v-btn>
                        </template>
                      </div>

                      <!-- Item nuevo -->
                      <process
                        v-if="
                          (template.pk > 0 ||
                            (product !== null && product.template > 0)) &&
                          addList[type] &&
                          processBtn.viewItem
                        "
                        :process="newProcess"
                        :product="product"
                        :type="type"
                        @done="submit"
                        ref="type"
                      />

                      <!-- Items guardados -->
                      <v-row
                        v-for="item in process"
                        :key="item !== null ? item.pk : 0"
                        class="my-0"
                      >
                        <v-col
                          v-if="
                            item !== null &&
                            item.typeProcess === type &&
                            processBtn.viewItem &&
                            (!sale ||
                              (sale &&
                                (item.subitems ||
                                  item.cost > 0 ||
                                  item.matrix_cost)))
                          "
                          cols="12"
                          class="pt-0"
                        >
                          <process
                            :process="item"
                            :product="product"
                            :sale="sale"
                            :subOrder="subOrder"
                            :level="level"
                            ref="type"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
import process from '../../components/templatesProduct/process.vue'
export default {
  components: {
    process
  },
  props: {
    container: {
      type: String,
      default: 'container'
    },
    product: {
      type: Object,
      default: null
    },
    sale: {
      type: Boolean,
      default: false
    },
    subOrder: {
      type: [Number, String],
      default: ''
    },
    level: {
      type: [Number, String],
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processCreate: {
        pk: '',
        template: '',
        name: '',
        formula: null,
        typeProcess: '',
        matrix: false,
        multi_choice: false,
        have_dimensions: false
      },
      addList: {
        preproduction: false,
        production: false,
        postproduction: false,
        extra: false
      },
      template: {
        pk: 0,
        name: '',
        company: ''
      },
      typeProcess: ['preproduction', 'production', 'postproduction', 'extra'],
      process: [],
      details: [],
      tab: null,
      panel: false,
      load: false,
      loading: false,
      detailsContainerfield: false
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      company: 'company/getCompanyData'
    }),
    newProcess() {
      return this.processCreate
    },
    /**
     * processBtn
     * Computado para permisos de plantilla de proceso
     * de producción
     * Para agregar o ver item en proceso de producción
     * de plantilla o de producto
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    processBtn() {
      return {
        addItem:
          this.getPermissions([`product.add_process`]) ||
          this.getPermissions([`product.add_productiondetails`]) ||
          this.isAdmin,
        viewItem:
          this.getPermissions([`product.view_process`]) ||
          this.getPermissions([`product.view_productiondetails`]) ||
          this.isAdmin
      }
    },
    /**
     * opts
     * Computado para modal de plantilla de proceso de producción
     * Se muestra cuando container es igual a modal
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t('add', { value: this.$tc('template', 1) }),
            value: this.value,
            load: this.isLoading2,
            maxWidth: '80%',
            dontSave: true
          }
        default:
          return { fluid: true }
      }
    }
  },
  methods: {
    submit() {
      try {
        this.load = true
        for (const type of this.typeProcess) {
          if (this.$refs[type] !== undefined) {
            if (this.addList[type]) {
              this.$refs[type].submit()
            } else {
              for (let i = 0; i < this.$refs[type].length; i++) {
                this.$refs[type][i].submit()
              }
            }
            this.dialog = true
          } else {
            this.addList[type] = false
          }
        }
      } finally {
        this.$toast.success(
          `${this.$tc('productive_process', 1)} ${this.$tc('edited', 2)}`
        )
        this.getTemplate()
        this.load = false
      }
    },
    /**
     * submitTemplate
     * Método para crear la plantilla de proceso de producción
     * con su nombre y matriz principal asociada
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submitTemplate() {
      if (this.template.pk === 0) {
        let res = await this.$api.product.template.create({
          form: this.template
        })
        this.template = res.data
      }
      if (this.template.pk > 0) {
        let res = await this.$api.product.template.edit({
          pk: this.template.pk,
          form: this.template
        })
        this.template = res.data
      }
      this.$toast.success(
        `${this.$tc('template', 1)} ${this.$tc(
          this.template.pk > 0 ? 'edited' : 'created',
          2
        )}`
      )
      this.processCreate.template = this.template.pk
      this.processCreate.main_matrix = this.template.main_matrix
    },
    /**
     * getTemplate
     * Método para consultar los atributos de la
     * plantilla de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getTemplate() {
      this.loading = true
      if (
        this.template.pk > 0 ||
        (this.product !== null
          ? this.product.template > 0
          : this.product !== null)
      ) {
        this.$api.product.template
          .show({
            pk: this.template.pk > 0 ? this.template.pk : this.product.template
          })
          .then((res) => {
            this.template = res.data
            this.processCreate.template = this.template.pk
            this.processCreate.main_matrix = this.template.main_matrix
            this.getProcess()
            this.$route.meta.title = this.$t('edit', { model: '' })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        let validation = this.codification({
          mode: 'decode',
          code: this.$route.params.pk
        })
        if (validation == 'create' && this.template.pk == 0) {
          if (this.$store.getters['utils/getIsloading']) {
            this.$store.dispatch('utils/actionLoading')
            this.$route.meta.title = this.$t('create', { model: '' })
          }
          this.template = {
            pk: 0,
            name: '',
            company: this.company.pk
          }
        }
        if (validation > 0 && this.product === null) {
          this.$api.product.template
            .show({ pk: validation })
            .then((res) => {
              this.template = res.data
              this.processCreate.template = this.template.pk
              this.processCreate.main_matrix = this.template.main_matrix
              this.getProcess()
              this.$route.meta.title = this.$t('edit', { model: '' })
            })
            .catch(() => this.$router.post({ name: 'templatesProduct' }))
        }
      }
      this.getProcess()
    },
    /**
     * getProcess
     * Método para consultar los ítems y subitems de
     * una plantilla de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getProcess() {
      if (this.product === null) {
        let res = await this.$api.product.processitem.list({
          opt: {
            params: {
              template: this.template.pk
            }
          }
        })
        this.process = res.data
      } else {
        if (this.product !== undefined && this.product.pk > 0) {
          let res = await this.$api.product.details.list({
            opt: {
              params: {
                product: this.product.pk
              }
            }
          })
          this.process = res.data
        }
      }
    }
  },
  mounted() {
    this.getTemplate()
  }
}
</script>
<style lang="sass">
.template-v-card-text
  min-height: 80vh
  min-height: 80dvh

.template-card-content
  position: relative

.template-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: calc(100% + 50px)
  z-index: 3
  border-radius: 15px
  font-size: 2rem
  display: flex
  justify-content: center
  align-items: center
  font-weight: 700
  color: var(--v-auxbg-base)
  text-align: center
  padding: 30px
  line-height: 2rem

.theme--light
  .template-overlay
    background-color: rgba(0, 0, 0, .6)
.theme--dark
  .template-overlay
    background-color: rgba(200, 200, 200, .75)

.theme--light.v-tabs-items
  border-top: 1px solid #f0f0f0
</style>
