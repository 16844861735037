<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('level', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <div class="modal-body">
      <v-row>
        <v-alert v-if="value" outlined type="info" dismissible>
          <li
            :class="{
              'font-weight-black': true,
              title: !$vuetify.breakpoint.xsOnly,
              'subtitle-1': $vuetify.breakpoint.xsOnly
            }"
          >
            {{ $t('registerLevel') }}
          </li>
        </v-alert>
        <v-col cols="12">
          <ValidationProvider
            vid="name"
            :name="$tc('name', 1)"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              autofocus
              name="name"
              :error-messages="errors[0]"
              v-model="level.name"
              outlined
              color="secondary"
              :label="$tc('name', 1)"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <ValidationProvider
            vid="description"
            :name="$tc('description', 1)"
            rules=""
            v-slot="{ errors }"
          >
            <v-skeleton-loader
              v-if="loading"
              type="article"
              tile
            ></v-skeleton-loader>
            <label v-if="!loading" for="description">
              {{ $t('description') }}
            </label>
            <vue-editor
              v-if="!loading"
              id="description"
              v-model="level.description"
              :editorToolbar="customToolbar"
            >
            </vue-editor>
            <span
              v-if="errors[0] != '' && errors[0] != null"
              class="areaErrors"
            >
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
    </div>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      level: {
        name: '',
        description: '',
        company_id: ''
      },
      edit: false,
      valid: false,
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.level =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { name: '', description: '', company_id: this.company.pk }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (!this.edit) {
            this.level.company_id = this.company.pk
            await this.$api.customer.level.create({ form: this.level })
          } else {
            this.level.company_id = this.company.pk
            await this.$api.customer.level.edit({
              pk: this.level.pk,
              form: this.level
            })
          }
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('level', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  }
}
</script>
