<route>
{
  "meta": {
    "permission": [
      "base_config.view_soldby"
    ]
  }
}
</route>

<template>
  <div>
    <dialmeasure
      ref="dialmeasure"
      :product="product"
      :to-edit="toEdit"
      @done="getProductSold"
      @first="$emit('firstCreation')"
      v-model="dialog"
    />
    <i-card-list
      api="product.measure"
      app="base_config.soldby"
      class="mt-2 px-2"
      dontSearch
      simple
      :headers="headers"
      :method="'show'"
      :opt="{ product: true }"
      :reload.sync="reload"
      :requestOpts="{ pk: product }"
      :title="$tc('soldby', 1)"
      :totalItems.sync="totalItems"
      @click:create="open(undefined, solds.length > 0 ? false : true)"
      @click:edit="open($event)"
    >
      <template v-slot:noData="{ value }">
        <span v-if="value" class="headline font-weight-black error--text">
          ({{ $t('measureRequired') }})
        </span>
      </template>
      <template v-slot:alert="{ value }">
        <v-alert
          v-if="value.length > 0 && value.findIndex((m) => m.default) == -1"
          outlined
          type="info"
        >
          {{ $t('default_field') }}
        </v-alert>
      </template>
      <template v-slot:[`item.rounded`]="{ value }">
        <span class="primary--text">
          <v-icon
            small
            :color="value ? 'success' : 'error'"
            v-text="value ? 'fa-check' : 'fa-times'"
          />
        </span>
      </template>
      <template v-slot:[`item.default`]="{ value }">
        <span class="primary--text">
          <v-icon
            small
            :color="value ? 'success' : 'error'"
            v-text="value ? 'fa-check' : 'fa-times'"
          />
        </span>
      </template>
    </i-card-list>
  </div>
</template>
<script>
import dialmeasure from './dialMeasure.vue'
export default {
  components: {
    dialmeasure
  },
  props: {
    product: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    reload: false,
    dialog: false,
    toEdit: null,
    totalItems: 0
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$tc('measure', 1),
          value: 'name'
        },
        {
          text: this.$t('formula_simple'),
          value: 'calc'
        },
        {
          text: this.$tc('rounded', 2),
          value: 'rounded'
        },
        {
          text: this.$t('default'),
          value: 'default'
        }
      ]
    }
  },
  watch: {
    product: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.getProductSold()
        }
      }
    },
    totalItems: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.getProductSold()
        }
      }
    }
  },
  methods: {
    open(item, first) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
      this.$refs.dialmeasure.getSolds(item, first)
    },
    async getProductSold() {
      this.loading = true
      try {
        let response = await this.$api.product.measure.show({
          pk: this.product,
          opt: {
            params: {
              product: true
            }
          }
        })
        this.solds = response.data
        this.$emit('changeSolds', this.solds.length)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
