var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i-modal',{attrs:{"title":_vm.setModalTitle,"value":_vm.value,"load":_vm.loading},on:{"submit":_vm.submit,"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-row',{staticClass:"my-0 align-items-center"},[(_vm.mode === 'special')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"products","name":_vm.$tc('product', 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{key:"selectProduct-input",staticClass:"secondary--text",attrs:{"autocomplete":"off","outlined":"","items":_vm.products,"item-text":"name","item-value":"pk","error-messages":errors[0],"label":_vm.$tc('product', 1),"clearable":""},on:{"change":_vm.setCost},model:{value:(_vm.productprice.product),callback:function ($$v) {_vm.$set(_vm.productprice, "product", $$v)},expression:"productprice.product"}})]}}],null,false,1089525564)})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"button","tile":""}}):_vm._e(),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.prodCost > 0 && !_vm.load),expression:"prodCost > 0 && !load"}],key:"autoprice-input",staticClass:"v-switch",attrs:{"color":"secondary","name":"autoprice","label":_vm.$t('bypercent')},model:{value:(_vm.productprice.autoprice),callback:function ($$v) {_vm.$set(_vm.productprice, "autoprice", $$v)},expression:"productprice.autoprice"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","sm":"4","outlined":""}},[_c('i-money',{staticClass:"font-weight-black grey--text",class:{ title: !_vm.$vuetify.breakpoint.xsOnly },attrs:{"mode":"text","label":_vm.$tc('cost', 2),"value":_vm.prodCost}})],1),(_vm.prodCost > 0 && _vm.productprice.product !== null)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","sm":"4"}},[_c('i-money',{class:{
          'font-weight-black': true,
          title: !_vm.$vuetify.breakpoint.xsOnly,
          'error--text': _vm.productprice.price - _vm.prodCost < 0,
          'success--text': _vm.productprice.price - _vm.prodCost >= 0
        },attrs:{"outlined":"","value":_vm.productprice.price - _vm.prodCost,"mode":"text","label":`${_vm.$tc('profit', 2)}:`}})],1):_vm._e(),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.prodCost > 0),expression:"prodCost > 0"}],class:{
        'pr-3': _vm.$vuetify.breakpoint.smAndUp
      },attrs:{"sm":"6","cols":"12"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"text","tile":""}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"percent","name":_vm.$tc('percent', 1),"rules":"required|double:2"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],key:"percent-input",attrs:{"outlined":"","error-messages":errors[0],"label":_vm.$t('percent'),"disabled":!_vm.productprice.autoprice},model:{value:(_vm.productprice.percent),callback:function ($$v) {_vm.$set(_vm.productprice, "percent", $$v)},expression:"productprice.percent"}})]}}])})],1),(_vm.prodCost > 0 && _vm.productprice.product !== null)?_c('v-col',{attrs:{"sm":"6","cols":"12"}},[(_vm.load)?_c('v-skeleton-loader',{attrs:{"type":"text","tile":""}}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"price","name":_vm.$tc('price', 0),"rules":{
          required: true,
          min_value: Number(_vm.prodCost)
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('i-money',{directives:[{name:"show",rawName:"v-show",value:(!_vm.load),expression:"!load"}],key:"price-input",attrs:{"outlined":"","error-messages":errors[0],"name":"price","label":_vm.$tc('price', 0),"disabled":_vm.productprice.autoprice},model:{value:(_vm.productprice.price),callback:function ($$v) {_vm.$set(_vm.productprice, "price", $$v)},expression:"productprice.price"}})]}}],null,false,3389860968)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.prodCost <= 0 && _vm.mode !== 'special'),expression:"prodCost <= 0 && mode !== 'special'"}],attrs:{"type":"warning","outlined":"","value":true}},[_c('span',{staticClass:"font-weight-black title"},[_vm._v(_vm._s(`${_vm.$tc('costalert')}`))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }